/** @jsx jsx */
import HomePageLayout from '../../../components/HomePageLayout';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import HeroSectionContainer from '@fify.at/gatsby-theme-base/src/components/HeroSection/HeroSectionContainer';
import TestimonialsExtendedSection from '../../../components/TestimonialsExtendedSection';

export default function Kundenmeinungen({ location, data }) {
  return (
    <HomePageLayout
      location={location}
      lang="de"
      title="Das sagen unsere zufriedenen Kunden"
      description="Finden Sie hilfreiche Kundenrezensionen und Rezensionsbewertungen unserer Dienstleistungen"
      image={data.hero.childImageSharp.fluid}
      keywords={['Zufriedene', 'Kunden']}
      header={{ attachment: 'default', appearance: 'default', fluid: true }}
    >
      <HeroSectionContainer
        fluid={false}
        orientation="southEast"
        variant="alpha"
        title="Das sagen unsere"
        subtitle=" zufriedenen Kunden"
        image={data.hero}
      />
      <TestimonialsExtendedSection
        background="white"
        testimonials={[
          {
            image: data.testimonialExtended_6.childImageSharp,
            text:
              'Da ich aufgrund einer Erkrankung nicht mehr selbst mein Haus fertigstellen konnte, wollte ich mich schnell und unkompliziert davon trennen. Die Immobilienexpertin von WirkaufendeinHaus.at hat mich ausführlich beraten und mich über fehlende Genehmigungen und daraus resultierende, mögliche Probleme aufgeklärt. Wir waren sehr zufrieden mit der guten Betreuung und raschen Abwicklung. Dank WirkaufendeinHaus.at kann ich meiner Lebensgefährtin und mir nun den großen Wunsch erfüllen und in ein neues Leben starten.',
            name: 'Robert H. aus Phyra',
            rating: '5',
            date: '20.10.2020',
          },
          {
            image: data.testimonialExtended_1.childImageSharp,
            text:
              'Ich habe das Objekt vor Jahren von meiner Mutter geerbt und aus sentimentalen Gründen nicht verkaufen wollen. Zeitweise war es billig vermietet, was das Haus aber nicht besser machte! Nach der Bewertung durch WirkaufendeinHaus.at war nach kurzer Bedenkzeit schnell klar, dass ich hier ohne Aufwand schnell verkaufen kann. Das hat für mich genau gepasst. Wenige Tage später waren wir beim Notar.',
            name: 'Robert K.',
            rating: '5',
            date: '16.05.2020',
          },
          {
            image: data.testimonialExtended_2.childImageSharp,
            text:
              'Nach meiner Scheidung habe ich versucht, mein Elternhaus zu erhalten. Ich rutschte immer mehr in Schulden hinein. Die Makler schwärmten von hohen Preisen und letztendlich nahm ich den Makler mit der höchsten Preiseinschätzung. Ein paar Monate später stand ich kurz vorm Konkurs! WirkaufendeinHaus.at hat mir ein gutes Angebot gemacht, obendrein noch direkt bei der Bank Aufschub erwirkt und alles für mich erledigt. Ich konnte das Haus dann sogar noch eine Zeit mieten, bis ich was anderes gefunden hatte!',
            name: 'Heinz W.',
            rating: 5,
            date: '15.05.2020',
          },
          {
            image: data.testimonialExtended_3.childImageSharp,
            text:
              'Meine Schwester und ich hatten das Haus gemeinsam mit 2 weiteren Objekten geerbt. Sofort nach Bekanntwerden des Todes unseres Vaters wurden wir mit Verkaufsanfragen überschüttet. Der Partner von WirkaufendeinHaus.at hat uns als einziger gefragt, was wir wirklich wollen! Und wir wollten einfach den Verkauf schnellstmöglich abwickeln! Bereits am nächsten Tag hatten wir schon ein Angebot vorliegen, das wir sofort angenommen haben, weil uns in der Situation die ewigen Verhandlungen mit den Interessenten zu mühsam waren. Und für das 3. Objekt hatte der Partner sogar schon einen vorgemerkten Käufer, der noch mehr zahlte! Alles war schnell und unkompliziert, wie wir es wollten.',
            name: 'Christine S.',
            rating: 5,
            date: '19.05.2020',
          },
          {
            image: data.testimonialExtended_4.childImageSharp,
            text:
              'Ich hatte ein Messiehaus zu verwalten, das aus einer überschuldeten Verlassenschaft stammte. Es taten sich zahlreiche Probleme auf. Es gab keine Baugenehmigung und vieles mehr. An einen Verkauf war so nicht zu denken! Über einen Bekannten wurde ich auf WirkaufendeinHaus.at aufmerksam. Ein zuständiger Experte hat mir gleich ein Angebot ausgehandelt, das mir den risikofreien Verkauf ermöglichte. Direkt aus der Verlassenschaft, die niemand antreten wollte. WirkaufendeinHaus.at hat das Objekt dann saniert, genehmigt und ein Käufer wurde im Handumdrehen gefunden.',
            name: 'Dr. Martin G.',
            position: 'Notar',
            rating: 5,
            date: '21.05.2020',
          },
          {
            image: data.testimonialExtended_5.childImageSharp,
            text:
              'Schon seit langem wollten wir uns von unserem ‚Bastlerhit‘ mit schöner Aussicht trennen. Der Andrang war enorm, jedes Wochenende Besichtigungen! Aber kaufen wollte niemand. Der WirkaufendeinHaus.at Partner hat mich dann mit seiner Bewertung und Erklärung wachgerüttelt und mich darüber informiert, dass Immobilientouristen auch bei viel zu hohen Preisen kommen. Ich habe den Preis dann angenommen, obwohl er unter meinen Erwartungen war, aber Burgenland ist eben nicht Wienerwald! Man muss realistisch bleiben. Damit hat mir WirkaufendeinHaus.at viele unnötige Fahrten und weitere Wochenenden erspart. Ich musste nur noch einmal zum Notar, um alles andere hat sich der Partner gekümmert.',
            name: 'Gabriele A.',
            rating: 5,
            date: '22.05.2020',
          },
        ]}
      />
    </HomePageLayout>
  );
}

export const pageQuery = graphql`
  {
    hero: file(name: { eq: "hero-home" }) {
      childImageSharp {
        fluid(maxWidth: 2340, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    testimonialExtended_1: file(name: { eq: "testimonialExtended_1" }) {
      childImageSharp {
        fluid(maxWidth: 700, fit: COVER, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    testimonialExtended_2: file(name: { eq: "testimonialExtended_2" }) {
      childImageSharp {
        fluid(maxWidth: 700, fit: COVER, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    testimonialExtended_3: file(name: { eq: "testimonialExtended_3" }) {
      childImageSharp {
        fluid(maxWidth: 700, fit: COVER, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    testimonialExtended_4: file(name: { eq: "testimonialExtended_4" }) {
      childImageSharp {
        fluid(maxWidth: 700, fit: COVER, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    testimonialExtended_5: file(name: { eq: "testimonialExtended_5" }) {
      childImageSharp {
        fluid(maxWidth: 700, fit: COVER, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    testimonialExtended_6: file(name: { eq: "testimonialExtended_6" }) {
      childImageSharp {
        fluid(maxWidth: 700, fit: COVER, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
